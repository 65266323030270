import React from "react";

import Layout from "../components/layout";

import awsLogo from "../images/aws.png";
import gcpLogo from "../images/gcp.png";

import "../components/resume.css";

const Resume = () => {
  return (
    <Layout>
      <div className="resume">
        <h1>David Cook</h1>

        <p>
          <a href="/davidcookresume.pdf" target="_blank">
            Download resume.
          </a>
        </p>

        <h2 class="sub-title">Full Stack Developer</h2>
        <div>Balaclava, Melbourne</div>
        <div>0409 66 11 32</div>
        <div>contact@davidcook.io</div>

        <h3 class="heading">Languages and Technologies</h3>

        <div className="row">
          <div>
            <h4>Languages</h4>
            <ul>
              <li>Javascript and Typescript</li>
              <li>HTML, CSS, SASS</li>
              <li>Python</li>
              <li>Worked with Go, Java, C++</li>
            </ul>
          </div>
          <div>
            <h4>Frameworks and Libraries</h4>
            <ul>
              <li>React Ecosystem</li>
              <li>Express, Koa</li>
              <li>Apollo</li>
              <li>TensorFlow</li>
            </ul>
          </div>
          <div>
            <h4>Related Technologies</h4>
            <ul>
              <li>AWS, GCP and Azure</li>
              <li>SQL and NoSQL Databases</li>
              <li>Microservices on Kubernetes</li>
              <li>GraphQL</li>
            </ul>
          </div>
        </div>

        <h3 class="heading">Certifications</h3>

        <div style={{ display: "flex" }}>
          <img src={awsLogo} style={{ height: 50, paddingRight: 15 }} />
          <div>
            <b>AWS Certified Solutions Architect</b>
            <p>
              An AWS exam about architecting and deploying secure, robust and
              scalable applications.
            </p>
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <img
            src={gcpLogo}
            style={{ height: 50, paddingLeft: 10, paddingRight: 18 }}
          />
          <div>
            <b>Professional Data Engineer</b>
            <p>
              A Google qualification about the design of data processing and
              machine learning systems.
            </p>
          </div>
        </div>

        <h3 class="heading">Work History</h3>

        <h4 class="heading3">Nintex</h4>
        <div class="text-muted">Aug 2019 - Present</div>
        <div class="text">
          Working as a Senior Software Engineer at Nintex, a SaaS leader in
          workflow automation.
        </div>
        <ul>
          <li>Worked with Javascript/Typescript, React, Node, Cosmos DB</li>
          <li>Microservice architecture backend on Azure Kubernetes</li>
          <li>Built the backbone of an event sourced backend system</li>
          <li>
            Significantly increased performance of key API routes (90% quicker)
          </li>
          <li>
            Successfully transitioned to effective remote working practices
          </li>
        </ul>

        <h4 class="heading3">Meld</h4>
        <div class="text-muted">Jan 2018 - May 2019</div>
        <div class="text">
          Worked as a full stack software engineer with a focus on the front
          end. Meld is a SASS platform for managing IoT devices including
          kiosks, digital signage, and enterprise devices.
        </div>
        <ul>
          <li>
            Worked in an agile team using Typescript, React, Redux, Node, GCP,
            and Jira
          </li>
          <li>
            Introduced CI with end-to-end testing to be run before every pull
            request
          </li>
          <li>Worked using analytics to make data-driven product decisions</li>
          <li>Lead the charge to migrate the system to Typescript</li>
          <li>Built resources to enable external developers on the platform</li>
        </ul>

        <h4 class="heading3">Clarity Coach</h4>
        <div class="text-muted">2016 - 2018</div>
        <div class="text">
          Founded Clarity Coach, a paid web app which uses Machine Learning to
          analyse Dota 2 matches and explains to users how to best improve.
        </div>
        <ul>
          <li>Typescript, React, Node, GraphQL, Mocha</li>
          <li>
            Used Complex Event Processing (CEP) and Machine Learning over event
            streams
          </li>
          <li>Responsible for every aspect of the business:</li>
          <li>Architecture and deployment of the codebase</li>
          <li>External API integration</li>
          <li>Attracting and keeping users on the platform</li>
          <li>Payment processing through Stripe</li>
        </ul>

        <h4 class="heading3">Freelance Website Design</h4>
        <div class="text-muted">2014 - 2017</div>
        <ul>
          <li>
            Freelance web development projects for clients, using Jade,
            Gatsbyjs, React, and Wordpress
          </li>
        </ul>

        <h3 class="heading">Projects</h3>

        <h4 class="heading3">Prediction Markets AI</h4>
        <div class="text">
          Built reinforcement learning models to autonomously participate in
          sports prediction markets.
        </div>
        <ul>
          <li>Tensorflow, Python, Keras, AWS</li>
          <li>
            Designed a feature extraction framework for structured event streams
          </li>
          <li>See an average yearly return of 1.14</li>
        </ul>

        <h3 class="heading">Academic</h3>

        <h4 class="heading3">The University of Melbourne</h4>
        <ul>
          <li>Undergraduate Degree in Science (Major in Computer Science)</li>
          <li>Obtained an Honours average</li>
        </ul>

        <h4 class="heading3">Caulfield Grammar School</h4>
        <ul>
          <li>Achieved ATAR score of 98.25</li>
          <li>1st place in the NCSS Programming Competition Advanced Stream</li>
        </ul>
      </div>
    </Layout>
  );
};

export default Resume;
